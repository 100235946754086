import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/dBW5ugqAwAI">
    <SEO title="Everything Moves at the Speed of Relationships - Relationships" />
  </Layout>
)

export default SermonPost
